
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { imageDb } from '../../Firebase';
import { getDownloadURL, ref, uploadBytes, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

const Teame = () => {
  const [editMode, setEditMode] = useState(false);
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', designation: '', image: '' });

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const fetchMembers = () => {
    axios.get('https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/get')
      .then((res) => {
        setMembers(res.data.Teams);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
           
                                               
  const handleAddMember = async (e) => {
    e.preventDefault();
    try {
      const imageFile = newMember.image;
      const imgRef = ref(imageDb, `team/${v4()}`);
      await uploadBytes(imgRef, imageFile);
      const url = await getDownloadURL(imgRef);

      const response = await axios.post('https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/add',
        { ...newMember, image: url },
        { headers: { 'Content-Type': 'application/json' } });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      setMembers([...members, { ...response.data, image: url }]);
      setNewMember({ name: '', designation: '', image: '' });
      console.log('New member added successfully!');
    } catch (error) {
      console.error('There was a problem adding the new member:', error.message);
    }
  };

 
  const handleDeleteImage = async id => {
    try {
      const response = await fetch(`https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/delete/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete testimonial');
      }

      fetchMembers();
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };

  return (
    <div className='int_container'>
      <div className="leaving">
        <h1 style={{ textAlign: "center" }}>Our Team</h1>
        <div className="img_living">
          {members.map((member, index) => {
            return (
              <div key={member._id}>
                {editMode ? (
                  <img src={member.image} alt={`Image ${index}`} />
                ) : (
                  <img src={member.image} alt={`Image ${index}`} />
                )}
                {editMode && (
                  <button onClick={() => handleDeleteImage(member._id, member.image, index)}>
                    <FaTrashAlt /> Delete
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div className="btn1">
          <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
        </div>
        {editMode && (
          <div className="edit-mode1">
            <h2 style={{ textAlign: "center" }}>Add New Team Member</h2>
            <form onSubmit={handleAddMember}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={newMember.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Designation:</label>
                <input
                  type="text"
                  name="designation"
                  value={newMember.designation}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Image:</label>
                <input
                  type="file"
                  name="image"
                  onChange={(e) => setNewMember({ ...newMember, image: e.target.files[0] })}
                  required
                />
              </div>
              <button type="submit">Add Member</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Teame;

























// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
// import { imageDb } from '../../Firebase';
// import { getDownloadURL, ref, uploadBytes, deleteObject } from "firebase/storage";
// import { v4 } from "uuid";

// const Teame = () => {
//   const [editMode, setEditMode] = useState(false);
//   const [teamMembers, setTeamMembers] = useState([]);
//   const [newMember, setNewMember] = useState({ name: '', designation: '', image: '' });

//   const toggleEditMode = () => {
//     setEditMode(!editMode);
//   };

//   const fetchTeamMembers = () => {
//     axios.get('https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/get')
//       .then((res) => {
//         setTeamMembers(res.data.Teams)
//       })
//       .catch((err) => {
//         console.log(err)
//       })
//   };

//   useEffect(() => {
//     fetchTeamMembers();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewMember(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleAddMember = async (e) => {
//     e.preventDefault();
//     try {
//       const imageFile = newMember.image;
//       const imgRef = ref(imageDb, `team/${v4()}`);
//       await uploadBytes(imgRef, imageFile);
//       const url = await getDownloadURL(imgRef);

//       const response = await axios.post('https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/add',
//         { ...newMember, image: url },
//         { headers: { 'Content-Type': 'application/json' } });

//       if (response.status !== 200) {
//         throw new Error('Network response was not ok');
//       }

//       setTeamMembers([...teamMembers, { _id: response.data._id, name: newMember.name, designation: newMember.designation, image: url }]);
//       setNewMember({ name: '', designation: '', image: '' });
//       console.log('New member added successfully!');
//     } catch (error) {
//       console.error('There was a problem adding the new member:', error.message);
//     }
//   };

//   const handleDeleteImage = async (id, imageUrl) => {
//     try {
//       const imgRef = ref(imageDb, `team/${imageUrl.split('/').pop().split('?')[0]}`);
//       await deleteObject(imgRef);

//       const response = await axios.delete(`https://vidya-laxmi-f9eddbcmasavhnex.canadacentral-01.azurewebsites.net/teams/delete/${id}`);
//       if (response.status !== 200) {
//         throw new Error('Network response was not ok');
//       }

//       setTeamMembers(teamMembers.filter(member => member._id !== id));
//       console.log('Team member deleted successfully!');
//     } catch (error) {
//       console.error('There was a problem deleting the image:', error.message);
//     }
//   };

//   return (
//     <div className='int_container'>
//       <div className="leaving">
//         <h1 style={{ textAlign: "center" }}>Our Team</h1>
//         <div className="img_living">
//           {teamMembers.map((member, index) => (
//             <div key={index}>
//               <img src={member.image} alt={`Image ${index}`} />
//               {editMode && (
//                 <button onClick={() => handleDeleteImage(member._id, member.image)}>
//                   <FaTrashAlt /> Delete
//                 </button>
//               )}
//             </div>
//           ))}
//         </div>
//         <div className="btn1">
//           <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
//         </div>
//         {editMode && (
//           <div className="edit-mode1">
//             <h2 style={{ textAlign: "center" }}>Add New Team Member</h2>
//             <form onSubmit={handleAddMember}>
//               <div>
//                 <label>Name:</label>
//                 <input
//                   type="text"
//                   name="name"
//                   value={newMember.name}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div>
//                 <label>Designation:</label>
//                 <input
//                   type="text"
//                   name="designation"
//                   value={newMember.designation}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div>
//                 <label>Image:</label>
//                 <input
//                   type="file"
//                   name="image"
//                   onChange={(e) => setNewMember({ ...newMember, image: e.target.files[0] })}
//                   required
//                 />
//               </div>
//               <button type="submit">Add Member</button>
//             </form>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Teame;

